import mmr2_10 from "../data/mmr/2.10.json";
import mze4_2 from "../data/mze/4-2.json";
import { includeVat, calculateCosts, getGroups } from "../utils";
import { ITypeQuantity } from "../interfaces";
import { defaultQuantity } from "./utils";

export function seepagePriceProvider(data: ITypeQuantity, method: string) {
  if (method === "mmr") {
    return calculateCosts(
      includeVat(
        (mmr2_10.data as any)[data.type] * data.quantity,
        !mmr2_10.vat_included
      ),
      mmr2_10.lifespan,
      true
    );
  }

  if (method === "mze") {
    return calculateCosts(
      includeVat(mze4_2.price * data.quantity, !mze4_2.vat_included),
      mze4_2.lifespan,
      true
    );
  }

  throw new Error("Method not implemented");
}

export function seepageGroupProvider(data: ITypeQuantity[]): ITypeQuantity[] {
  return getGroups(data, ["type"], ["quantity"]);
}

export function seepageOptionProvider() {
  return Object.keys(mmr2_10.data);
}

export const seepageDefaultState = {
  type: "Do 1,5m",
  quantity: defaultQuantity,
};
