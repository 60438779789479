import mmr_3_11 from "../data/mmr/3.11.json";
import { includeVat } from "../utils";
import { defaultQuantity } from "./utils";

export function sanaceRoadProvider(data: number, method: string) {
  const genuine = "mmr" === method;

  return {
    investment: includeVat(mmr_3_11.data.road * data, !mmr_3_11.vat_included),
    genuine,
    min: 0,
    max: 0,
  };
}

export const sanaceRoadDefaultState = defaultQuantity;
