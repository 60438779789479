import pdfMake from "pdfmake/build/pdfmake";
import { IVariant, IVariantDCOV } from "../interfaces";
import getPreface from "./preface";
import getTableOfContents from "./tableOfContents";
import getVariantDCOV from "./variantDCOV";
import getVariants from "./variants";
import getSummary from "./summary";

// Setup fonts
pdfMake.fonts = {
  Roboto: {
    normal: "https://fonts.cdnfonts.com/s/15959/PT_Serif-Web-Regular.ttf",
    bold: "https://fonts.cdnfonts.com/s/15959/PT_Serif-Web-Bold.ttf",
    italics: "https://fonts.cdnfonts.com/s/15959/PT_Serif-Web-Italic.ttf",
    bolditalics:
      "https://fonts.cdnfonts.com/s/15959/PT_Serif-Web-BoldItalic.ttf",
  },
};

// Generate PDF and return it as a blob
const generatePdf = async (
  variants: IVariant[],
  dcov: IVariantDCOV
): Promise<Blob> => {
  // Document definition
  const definition = {
    // Document content
    content: [
      ...getPreface(),
      ...getTableOfContents(variants),
      ...(await getVariantDCOV(dcov, variants)),
      ...(await getVariants(dcov, variants)),
      ...getSummary(dcov, variants),
    ],
    // Document styles
    styles: {
      h1: {
        fontSize: 26,
        bold: true,
        alignment: "center",
      },
      h2: {
        fontSize: 22,
        bold: true,
      },
      h3: {
        fontSize: 18,
        bold: true,
      },
      h4: {
        fontSize: 13,
        decoration: "underline",
      },
      tableHeader: {
        bold: true,
      },
      link: {
        decoration: "underline",
        color: "#000f94",
      },
    },
    // Footer
    footer: (currentPage: number) => ({
      text: currentPage.toString(),
      alignment: "center",
    }),
  };

  // Generate PDF
  const docGenerator = pdfMake.createPdf(definition as any);
  return new Promise((resolve) => {
    docGenerator.getBlob((blob) => resolve(blob));
  });
};

export default generatePdf;
