import ReactDOM from "react-dom";
import { toPng } from "html-to-image";
import DisposalChart from "../components/DisposalChart";

// Render React Recharts chart to png as string url
const renderDisposalChart = (data: any): Promise<string> => {
  // Chart dimensions
  const width = 1500;
  const height = 800;

  return new Promise((resolve) => {
    // Setup elements
    const el = document.createElement("div");
    const chart = (
      <DisposalChart data={data} forRender width={width} height={height} />
    );

    // Render the chart into div outside the main DOM
    ReactDOM.render(chart, el, () => {
      // Convert HTML to PNG and resolve the promise
      toPng(el, {
        width,
        height,
      }).then((data) => {
        if (data === null) {
          throw new Error("Image generation failed!");
        }
        resolve(data);
      });
    });
  });
};

export default renderDisposalChart;
