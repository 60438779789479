import Price from "../components/Price";
import { IPriceQuantity } from "../interfaces";
import { Grid, List, ListItem, Divider, TextField } from "@mui/material";
import {
  sanaceReconstructionBigGroupProvider,
  sanaceReconstructionBigProvider,
} from "../providers/sanaceReconstructionBigProvider";
import { Fragment } from "react";

interface IProps {
  data: IPriceQuantity[];
}

export default function SummarySanaceReconstructionBig({ data }: IProps) {
  const groups = sanaceReconstructionBigGroupProvider(data);
  const quantity = groups.reduce((acc, curr) => acc + Number(curr.quantity), 0);

  if (groups.length === 0) {
    return <></>;
  }

  return (
    <List>
      {groups.map((group, index) => (
        <Fragment key={index}>
          <ListItem disableGutters>
            <Grid
              container
              spacing={2}
              sx={{ marginRight: 23 }}
              alignItems="center"
            >
              <Grid item xs={3}>
                Mezisoučet #{index + 1}
              </Grid>
              <Grid
                item
                xs={9}
                container
                key={index}
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={5}>
                  <TextField
                    label="Cena (ks)"
                    size="small"
                    value={group.price}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Celkový počet"
                    size="small"
                    value={group.quantity}
                    InputProps={{ readOnly: true }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={5}>
                  <Price
                    data={group}
                    provider={sanaceReconstructionBigProvider}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
        </Fragment>
      ))}
      <ListItem disableGutters>
        <Grid
          container
          spacing={2}
          sx={{ marginRight: 23 }}
          alignItems="center"
        >
          <Grid item xs={3}>
            Celkový součet
          </Grid>
          <Grid item xs={9} container alignItems="center" spacing={2}>
            <Grid item xs={5}></Grid>
            <Grid item xs={2}>
              <TextField
                label="Celkový počet"
                size="small"
                value={quantity}
                InputProps={{ readOnly: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={2}>
              <Price
                data={groups}
                isArray={true}
                provider={sanaceReconstructionBigProvider}
              />
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
}
