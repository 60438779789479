import mmr_psl from "../data/mmr/pressuredStationLine.json";
import mze_psl from "../data/mze/pressuredStationLine.json";
import { ITypeQuantity } from "../interfaces";
import { calculateCosts, getGroups, includeVat, sumCalc } from "../utils";
import { defaultQuantity } from "./utils";

export function pressuredStationLinePriceProvider(
  data: ITypeQuantity,
  method: string
) {
  if (method === "mmr") {
    const moneyBuilding = mmr_psl.building.price * data.quantity;
    const calculationBuilding = calculateCosts(
      includeVat(moneyBuilding, !mmr_psl.vat_included),
      mmr_psl.building.lifespan,
      true
    );
    const moneyTechnology = mmr_psl.technology.price * data.quantity;
    const calculationTechnology = calculateCosts(
      includeVat(moneyTechnology, !mmr_psl.vat_included),
      mmr_psl.technology.lifespan,
      true
    );
    return sumCalc([calculationBuilding, calculationTechnology]);
  } else if (method === "mze") {
    const money = (mze_psl as any).data[data.type] * data.quantity;
    return calculateCosts(
      includeVat(money, !mze_psl.vat_included),
      mze_psl.lifespan,
      true
    );
  }
  throw new Error("Unknown method");
}

export function pressuredStationLineOptionProvider() {
  return Object.keys(mze_psl.data);
}

export function pressuredStationLineGroupProvider(
  data: ITypeQuantity[]
): ITypeQuantity[] {
  return getGroups(data, ["type"], ["quantity"]);
}

export const pressuredStationLineDefaultState = {
  type: "1 l/s",
  quantity: defaultQuantity,
};
