import { IVariant } from "../../interfaces";
import {
  pressuredStationLineGroupProvider,
  pressuredStationLinePriceProvider,
} from "../../providers/pressuredStationLineProvider";
import { getPrice } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getPressuredStationLine = (variant: IVariant) => {
  const filtered = variant.pressuredStationLine.filter((g) => g.quantity > 0);
  const groups = pressuredStationLineGroupProvider(filtered);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    { text: "Kanalizace tlaková - čerpací stanice řadová", style: "h4" },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: ["auto", "auto", "auto", "*"],
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Kapacita", style: "tableHeader" },
            { text: "Počet", style: "tableHeader" },
            { text: "Cena", style: "tableHeader" },
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            g.type,
            g.quantity,
            getPrice(g, pressuredStationLinePriceProvider)[1],
          ]),
          ["", "", "", ""],
          ...groups.map((g, index) => [
            `Mezisoučet #${index + 1}`,
            g.type,
            g.quantity,
            getPrice(g, pressuredStationLinePriceProvider)[1],
          ]),
          [
            "Celkový součet",
            "",
            "",
            getPrice(filtered, pressuredStationLinePriceProvider)[1],
          ],
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getPressuredStationLine;
