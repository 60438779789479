import { INonTable } from "../interfaces";
import { calculateCosts, includeVat } from "../utils";
import { defaultQuantity } from "./utils";

export function nonTablePriceProvider(data: INonTable, method: string) {
  return calculateCosts(
    includeVat(Number(data.price), !data.withVAT),
    { min: Number(data.min) ?? 0, max: Number(data.max) ?? 0 },
    true
  );
}

export const nonTableDefaultState: INonTable = {
  text: "",
  price: defaultQuantity,
  withVAT: true,
  min: 50,
  max: 100,
};
