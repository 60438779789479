import { IVariant, IVariantDCOV } from "../../interfaces";
import { getItemMargin, getOstr } from "../utils";
import { getPrice, getWidths, getPriceHeader } from "../price";
import {
  seepageGroupProvider,
  seepagePriceProvider,
} from "../../providers/seepageProvider";

const getSeepage = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.seepage.filter((g) => g.quantity > 0);
  const groups = seepageGroupProvider(filtered);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    { text: "Bodový vsakovací objekt za ČOV", style: "h4" },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: getWidths(3),
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Hloubka", style: "tableHeader" },
            { text: "Počet", style: "tableHeader" },
            ...getPriceHeader(),
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            g.type,
            g.quantity,
            ...getPrice(g, seepagePriceProvider),
          ]),
          ["", "", "", "", ""],
          ...groups.map((g, index) => [
            `Mezisoučet #${index + 1}`,
            g.type,
            g.quantity,
            ...getPrice(g, seepagePriceProvider),
          ]),
          [
            "Celkový součet",
            "",
            "",
            ...getPrice(filtered, seepagePriceProvider),
          ],
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getSeepage;
