import { IVariant } from "../../interfaces";
import { getItemMargin, getOstr } from "../utils";
import { getPrice, getWidths, getPriceHeader } from "../price";
import {
  pressuredGroupProvider,
  pressuredPriceProvider,
} from "../../providers/pressuredProvider";

const getPressured = (variant: IVariant) => {
  const filtered = variant.pressured.filter((g) => g.quantity > 0);
  const groups = pressuredGroupProvider(filtered);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    { text: "Kanalizace tlaková", style: "h4" },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: getWidths(5),
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Materiál", style: "tableHeader" },
            { text: "Profil", style: "tableHeader" },
            { text: "Délka", style: "tableHeader" },
            { text: "Zpevněné plochy", style: "tableHeader" },
            ...getPriceHeader(),
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            g.type,
            g.profile,
            g.quantity,
            g.consolidated ? "Ano" : "Ne",
            ...getPrice(g, pressuredPriceProvider),
          ]),
          ["", "", "", "", "", "", ""],
          ...groups.map((g, index) => [
            `Mezisoučet #${index + 1}`,
            g.type,
            g.profile,
            g.quantity,
            g.consolidated ? "Ano" : "Ne",
            ...getPrice(g, pressuredPriceProvider),
          ]),
          [
            "Celkový součet",
            "",
            "",
            "",
            "",
            ...getPrice(filtered, pressuredPriceProvider),
          ],
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getPressured;
