import { TextField, Grid } from "@mui/material";
import { indicativeGeoPriceProvider } from "../providers/indicativeGeoProvider";
import Price from "../components/Price";

interface IProps {
  setState: (state: any) => void;
  state: any;
}

export default function ItemIndicativeGeo(props: IProps) {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <TextField
          label="Počet"
          size="small"
          value={props.state.quantity}
          onChange={(e) =>
            props.setState({ ...props.state, quantity: e.target.value })
          }
          type="number"
          inputProps={{ min: 0 }}
          sx={{ pr: 1 }}
          fullWidth
        />
      </Grid>
      <Grid item xs={9}>
        <Price data={props.state} provider={indicativeGeoPriceProvider} />
      </Grid>
    </Grid>
  );
}
