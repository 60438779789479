import disposalData from "../data/special/disposal.json";
import { IDisposal } from "../interfaces";

const generateLinksFromTree = (
  tree: any,
  nodes: {
    name: string;
    key: string;
  }[],
  parentIndex?: number
) => {
  const links: any = [];

  Object.entries(tree).forEach(([key, node]: [string, any]) => {
    if (key !== "end") {
      nodes.push({
        name: (disposalData as any)[key].title,
        key,
      });
    }
    const currentIndex = nodes.length - 1;

    if (typeof parentIndex !== "undefined") {
      links.push({
        // If end connect to special node
        source: key === "end" ? 0 : currentIndex,
        target: parentIndex,
        value: node.value,
      });
    }

    if (Object.keys(node.children).length > 0) {
      const childrenLinks = generateLinksFromTree(
        node.children,
        nodes,
        currentIndex
      );
      links.push(...childrenLinks);
    }
  });

  return links;
};

export function disposalChartProvider(data: IDisposal[]) {
  const tree: any = {};

  data.forEach((line) => {
    const { path, name } = line;
    const filteredPath = path.filter(
      (node) => !(disposalData as any)[node].hidden
    );
    let current = tree;

    // Iterate from reverse
    for (let i = filteredPath.length - 1; i >= 0; i--) {
      // Mark end node
      const node = i === 0 ? "end" : filteredPath[i];

      // Create node if not exists
      if (!current[node]) {
        current[node] = {
          title: name,
          value: 1,
          children: {},
        };
      } else {
        current[node].value++;
      }

      // Set current node
      current = current[node].children;
    }
  });

  const nodes: any[] = [{ name: disposalData["17"].title, key: "17" }];
  const links = generateLinksFromTree(tree, nodes);

  return {
    nodes: nodes,
    links: links,
  };
}

export const disposalDefaultState = {
  name: "",
  path: [],
};
