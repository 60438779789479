import { ICalculation } from "../interfaces";
import { currencyFormatter, sumCalc } from "../utils";

export const getPriceHeader = () => [
  { text: "" },
  { text: "Cena", style: "tableHeader" },
];

export const getWidths = (numColumns: number) => {
  const widths = new Array(numColumns).fill("auto"); // Existing columns
  widths.push("*"); // Space between existing columns and price
  widths.push("auto"); // Price
  return widths;
};

export const getPrice = (
  data: any,
  provider: (data: any, method: string) => ICalculation
) => {
  let costMMR, costMZe;
  if (Array.isArray(data)) {
    costMMR = data.reduce(
      (acc: ICalculation, curr: any) => sumCalc([provider(curr, "mmr"), acc]),
      { min: 0, max: 0, investment: 0, genuine: true }
    );
    costMZe = data.reduce(
      (acc: ICalculation, curr: any) => sumCalc([provider(curr, "mze"), acc]),
      { min: 0, max: 0, investment: 0, genuine: true }
    );
  } else {
    costMMR = provider(data, "mmr");
    costMZe = provider(data, "mze");
  }

  let buffer = "";

  // MMR
  if (costMMR.genuine) {
    buffer += "MMR\n";
    buffer += `- Investice ${currencyFormatter(costMMR.investment)}\n`;
    buffer += `- Náklady min. ${currencyFormatter(costMMR.min)}\n`;
    buffer += `- Náklady max. ${currencyFormatter(costMMR.min)}\n`;
  }

  // MZe
  if (costMZe.genuine) {
    buffer += "MZe\n";
    buffer += `- Investice ${currencyFormatter(costMZe.investment)}\n`;
    buffer += `- Náklady min. ${currencyFormatter(costMZe.min)}\n`;
    buffer += `- Náklady max. ${currencyFormatter(costMZe.min)}\n`;
  }

  return ["", buffer];
};
