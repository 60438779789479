import Price from "../components/Price";
import { IQuantity } from "../interfaces";
import { Grid, List, ListItem, TextField } from "@mui/material";
import { pressuredStationPriceProvider } from "../providers/pressuredStationProvider";

interface IProps {
  data: IQuantity[];
}

export default function SummaryPressuredStation({ data }: IProps) {
  const quantity = data.reduce((acc, curr) => acc + Number(curr.quantity), 0);

  if (quantity === 0) {
    return <></>;
  }

  return (
    <List>
      <ListItem disableGutters>
        <Grid
          container
          spacing={2}
          sx={{ marginRight: 23 }}
          alignItems="center"
        >
          <Grid item xs={3}>
            Celkový součet
          </Grid>
          <Grid item xs={9} container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <TextField
                label="Celkový počet"
                size="small"
                value={quantity}
                InputProps={{ readOnly: true }}
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={2}>
              <Price
                data={data}
                isArray={true}
                provider={pressuredStationPriceProvider}
              />
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
}
