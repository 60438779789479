import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { IDisposal } from "../interfaces";
import disposalData from "../data/special/disposal.json";

interface IProps {
  state: IDisposal;
  setState: (state: any) => void;
}

export default function ItemDisposal({ state, setState }: IProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const startingPath = ["17"];
  const [temporaryPath, setTemporaryPath] = useState<string[]>(startingPath);
  const isPathSet = state.path.length > 0;

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTemporaryPath(startingPath);
  };

  const advancePath = (option: string) => {
    if ((disposalData as any)[option].end) {
      setState({ ...state, path: [...temporaryPath, option] });
      handleDialogClose();
    } else {
      setTemporaryPath([...temporaryPath, option]);
    }
  };

  const returnPath = () => {
    setTemporaryPath(temporaryPath.slice(0, temporaryPath.length - 1));
  };

  const deletePath = () => {
    setState({ ...state, path: [] });
    handleDialogClose();
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        {isPathSet ? (
          <>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                onClick={() => setDialogOpen(true)}
                fullWidth
              >
                Změnit cestu
              </Button>
            </Grid>
            <Grid item xs={9} container alignItems="center">
              {state.path
                .filter((node) => !(disposalData as any)[node].hidden)
                .map((node, index) => (
                  <Grid item key={index}>
                    <Tooltip
                      title={(disposalData as any)[node].title}
                      placement="top"
                    >
                      <img
                        src={`img/icons/${(disposalData as any)[node].icon}`}
                        style={{ width: "100px" }}
                        alt=""
                      />
                    </Tooltip>
                  </Grid>
                ))}
            </Grid>
          </>
        ) : (
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={() => setDialogOpen(true)}
              fullWidth
            >
              Vyberte cestu
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Zvolte typ likvidace</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">Aktuální cesta</Typography>
          <Grid container alignItems="center">
            {temporaryPath
              .filter((node) => !(disposalData as any)[node].hidden)
              .map((node, index) => (
                <Grid item key={index}>
                  <Tooltip
                    title={(disposalData as any)[node].title}
                    placement="top"
                  >
                    <img
                      src={`img/icons/${(disposalData as any)[node].icon}`}
                      style={{ width: "100px" }}
                      alt=""
                    />
                  </Tooltip>
                </Grid>
              ))}
          </Grid>
          <Typography variant="subtitle2">Výběr</Typography>
          <Grid container spacing={2}>
            {Object.entries(disposalData)
              .filter(([_, option]: any) => !option.hidden)
              .sort(([_, a]: any, [__, b]: any) => a.order - b.order)
              .map(([key, option]: any, index) => (
                <Grid item xs={2} key={index}>
                  <Button onClick={() => advancePath(key)}>
                    <img src={`img/icons/${option.icon}`} alt="" />
                  </Button>
                  <Typography fontSize={13}>{option.title}</Typography>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {temporaryPath.length > 1 && (
            <Button onClick={returnPath}>Zpět</Button>
          )}
          {state.path.length > 0 && (
            <Button onClick={deletePath}>Smazat</Button>
          )}
          <Button onClick={handleDialogClose}>Zrušit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
