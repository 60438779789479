import detailedGeo from "../data/mmr/detailedGeo.json";
import { IQuantity } from "../interfaces";
import { includeVat } from "../utils";
import { defaultQuantity } from "./utils";

export function detailedGeoPriceProvider(data: IQuantity, method: string) {
  const genuine = method === "mmr";
  const money = includeVat(
    detailedGeo.price * data.quantity,
    !detailedGeo.vat_included
  );
  return { investment: money, min: 0, max: 0, genuine };
}

export const detailedGeoDefaultState: IQuantity = {
  quantity: defaultQuantity,
};
