import Price from "../components/Price";
import { INonTable } from "../interfaces";
import { Grid, List, ListItem } from "@mui/material";
import { nonTablePriceProvider } from "../providers/nonTableProvider";

interface IProps {
  data: INonTable[];
}

export default function SummaryNonTable({ data }: IProps) {
  const price = data.reduce((acc, curr) => acc + Number(curr.price), 0);

  if (price === 0) {
    return <></>;
  }

  return (
    <List>
      <ListItem disableGutters>
        <Grid
          container
          spacing={2}
          sx={{ marginRight: 23 }}
          alignItems="center"
        >
          <Grid item xs={3}>
            Celkový součet
          </Grid>
          <Grid item xs={9} container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Price
                data={data}
                isArray={true}
                provider={nonTablePriceProvider}
              />
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
}
