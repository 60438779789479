import { IVariant, IVariantDCOV } from "../../interfaces";
import {
  sanaceGroupProvider,
  sanaceProvider,
} from "../../providers/sanaceProvider";
import { getPrice } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getSanace = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.sanace.filter((g) => g.quantity > 0);
  const groups = sanaceGroupProvider(filtered);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    { text: "Rekonstrukce kanalizace", style: "h4" },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: ["auto", "auto", "auto", "*"],
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Profil", style: "tableHeader" },
            { text: "Délka", style: "tableHeader" },
            { text: "Cena", style: "tableHeader" },
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            g.type,
            g.quantity,
            getPrice(g, sanaceProvider)[1],
          ]),
          ["", "", "", ""],
          ...groups.map((g, index) => [
            `Mezisoučet #${index + 1}`,
            g.type,
            g.quantity,
            getPrice(g, sanaceProvider)[1],
          ]),
          ["Celkový součet", "", "", getPrice(filtered, sanaceProvider)[1]],
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getSanace;
