import { produce } from "immer";
import {
  List,
  ListItem,
  Divider,
  Box,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ItemDivider from "../components/ItemDivider";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ReplyIcon from "@mui/icons-material/Reply";

interface IState {
  Component: (a: any, b: any) => JSX.Element;
  SummaryComponent?: (a: any) => JSX.Element;
  state: any[];
  newItemData: object;
  update: (state: any) => void;
  noName?: boolean;
  thickDivider?: boolean;
  sendButton?: boolean;
  alignItems?: "center" | "end";
  onSend?: (item: any) => void;
}

/**
 * Renders a list of components of the same type (gravitational sewerage, storage tank, etc.)
 *
 * @param Component The single component to be rendered
 * @param update Callback to update the state
 * @param state The current state of the component
 * @param newItemData The data to be passed to the new component (item) in the list
 */
export default function ItemStack({
  Component,
  SummaryComponent,
  state,
  update,
  newItemData,
  noName,
  thickDivider,
  sendButton,
  onSend,
  alignItems = "end",
}: IState) {
  const reorderItems = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    update(
      produce(state, (draft) => {
        const [removed] = draft.splice(result.source.index, 1);
        draft.splice(result.destination?.index!, 0, removed);
      })
    );
  };

  const addItem = (index: number) => {
    update(
      produce(state, (draft) => {
        draft.splice(index + 1, 0, newItemData);
      })
    );
  };

  return (
    <DragDropContext onDragEnd={reorderItems}>
      <Droppable droppableId="asdfasdf" direction="vertical">
        {(props) => (
          <List ref={props.innerRef} {...props.droppableProps}>
            {state.map((subState, index) => (
              <Draggable draggableId={`id-${index}`} index={index} key={index}>
                {(props, snapshot) => (
                  <Box ref={props.innerRef} {...props.draggableProps}>
                    <ListItem disableGutters key={index * 2}>
                      <Grid container alignItems={alignItems} spacing={2}>
                        <Grid container spacing={2} alignItems="center" item xs>
                          {!noName && (
                            <Grid item xs={3}>
                              <TextField
                                value={subState.name ?? ""}
                                onChange={(e) => {
                                  update(
                                    produce(state, (draft) => {
                                      draft[index].name = e.target.value;
                                    })
                                  );
                                }}
                                size="small"
                                label="Název větve"
                                fullWidth
                              />
                            </Grid>
                          )}
                          <Grid item xs={noName ? 12 : 9}>
                            <Component
                              state={subState}
                              setState={(newData: any) => {
                                update(
                                  produce(state, (draft) => {
                                    draft[index] = newData;
                                  })
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                        {sendButton && (
                          <Grid item>
                            <IconButton
                              edge="end"
                              onClick={() => onSend?.(subState)}
                            >
                              <ReplyIcon />
                            </IconButton>
                          </Grid>
                        )}
                        <Grid item>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              update(
                                produce(state, (draft) => {
                                  if (draft.length !== 1) {
                                    draft.splice(index, 1);
                                  } else {
                                    return [newItemData];
                                  }
                                })
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={() => addItem(index)}
                          >
                            <AddIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton {...props.dragHandleProps}>
                            <DragIndicatorIcon
                              style={{ color: "rgba(0,0,0,0.5)" }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </ListItem>

                    {state.length - 1 !== index &&
                      !snapshot.isDragging &&
                      (thickDivider ? <ItemDivider /> : <Divider />)}
                  </Box>
                )}
              </Draggable>
            ))}
            {props.placeholder}
            {typeof SummaryComponent !== "undefined" && (
              <SummaryComponent data={state} />
            )}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}
