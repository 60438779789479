import {
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";
import Price from "../components/Price";
import { ITypeQuantity } from "../interfaces";
import {
  pressuredStationLineOptionProvider,
  pressuredStationLinePriceProvider,
} from "../providers/pressuredStationLineProvider";

interface IProps {
  state: ITypeQuantity;
  setState: (state: any) => void;
}

export default function ItemPressuredStationLine(props: IProps) {
  const types = pressuredStationLineOptionProvider();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={5}>
        <FormControl fullWidth>
          <InputLabel>Kapacita</InputLabel>
          <Select
            label="Kapacita"
            value={props.state.type}
            size="small"
            onChange={(e) =>
              props.setState({ ...props.state, type: e.target.value })
            }
          >
            {types.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField
            label="Počet"
            size="small"
            value={props.state.quantity}
            onChange={(e) =>
              props.setState({ ...props.state, quantity: e.target.value })
            }
            type="number"
            inputProps={{ min: 0 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <Price
          data={props.state}
          provider={pressuredStationLinePriceProvider}
        />
      </Grid>
    </Grid>
  );
}
