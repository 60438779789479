import { IVariant, IVariantDCOV } from "../../interfaces";
import { detailedGeoPriceProvider } from "../../providers/detailedGeoProvider";
import { getPrice, getWidths, getPriceHeader } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getDetailedGeo = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.detailedGeo.filter((g) => g.quantity > 0);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    {
      stack: [
        {
          text: "Podrobný hydrogeologický průzkum (pro vsakovací objekt)",
          style: "h4",
        },
        {
          layout: "lightHorizontalLines",
          table: {
            widths: getWidths(2),
            body: [
              [
                { text: "Název větve", style: "tableHeader" },
                { text: "Počet", style: "tableHeader" },
                ...getPriceHeader(),
              ],
              ...filtered.map((g) => [
                getOstr(g.name),
                g.quantity,
                ...getPrice(g, detailedGeoPriceProvider),
              ]),
              ["", "", "", ""],
              [
                "Celkový součet",
                "",
                ...getPrice(filtered, detailedGeoPriceProvider),
              ],
            ],
          },
        },
      ],
      unbreakable: true,
      ...getItemMargin(),
    },
  ];
};

export default getDetailedGeo;
