import mmr3_7 from "../data/mmr/3.7.json";
import { ISewerageConnection } from "../interfaces";
import { includeVat, calculateCosts, getGroups } from "../utils";
import { defaultQuantity } from "./utils";

export function gravitationalConnectionOptionProvider(
  state: ISewerageConnection
) {
  const types = Object.keys(mmr3_7.data);
  const profiles = Object.entries((mmr3_7.data as any)[state.type])
    .filter((entry) => entry[1] != null)
    .map((entry) => entry[0]);

  return { types, profiles };
}

export function gravitationalConnectionPriceProvider(
  data: ISewerageConnection,
  method: string
) {
  const genuine = method === "mmr";
  const type = (mmr3_7.data as any)[data.type];
  const money = includeVat(
    type[data.profile]! * data.quantity,
    !mmr3_7.vat_included
  );
  return calculateCosts(money, mmr3_7.lifespan, genuine);
}

export function gravitationalConnectionGroupProvider(
  data: ISewerageConnection[]
): ISewerageConnection[] {
  return getGroups(data, ["type", "profile"], ["quantity"]);
}

export const gravitationalConnectionDefaultState = {
  type: "plastové",
  profile: "150",
  quantity: defaultQuantity,
};
