import disposalData from "../data/special/disposal.json";

import {
  ResponsiveContainer,
  Sankey,
  Tooltip,
  Layer,
  Rectangle,
} from "recharts";
import { pseudoRandom } from "../utils";

interface IDisposalChartData {
  nodes: string[];
  links: {
    source: number;
    target: number;
    value: number;
  }[];
}

interface IProps {
  data: IDisposalChartData;
  width?: number;
  height?: number;
  forRender?: boolean;
}

export default function DisposalChart({
  data,
  forRender,
  width,
  height,
}: IProps) {
  const MyCustomNode = ({
    x,
    y,
    width,
    height,
    index,
    payload,
    containerWidth,
  }: any) => {
    const isOut = x + width + 6 > containerWidth;

    const end = payload.key === "14" || payload.key === "15";

    return (
      <Layer key={`CustomNode${index}`}>
        <Rectangle
          x={x}
          y={y}
          width={width}
          height={height}
          fill="#5192ca"
          fillOpacity="1"
        />
        {end ? (
          <>
            <text
              textAnchor={isOut ? "end" : "start"}
              x={isOut ? x - 6 + 45 : x + width + 6 + 45}
              y={y + height / 2}
              fontSize="14"
              stroke="#333"
            >
              <tspan
                x={isOut ? x - 6 + 45 : x + width + 6 + 45}
                y={y + height / 2 - 5}
              >
                {payload.name.split(", ")[0]},
              </tspan>
              <tspan
                x={isOut ? x - 6 + 45 : x + width + 6 + 45}
                y={y + height / 2 + 13 - 5}
              >
                {payload.name.split(", ")[1]}
              </tspan>
            </text>
            <text
              textAnchor={isOut ? "end" : "start"}
              x={isOut ? x - 6 + 45 : x + width + 6 + 45}
              y={y + height / 2 + 26 - 3}
              fontSize="12"
              stroke="#333"
              strokeOpacity="0.5"
            >
              {payload.value}
            </text>
          </>
        ) : (
          <>
            <text
              textAnchor={isOut ? "end" : "start"}
              x={isOut ? x - 6 + 45 : x + width + 6 + 45}
              y={y + height / 2}
              fontSize="14"
              stroke="#333"
            >
              {payload.name}
            </text>
            <text
              textAnchor={isOut ? "end" : "start"}
              x={isOut ? x - 6 + 45 : x + width + 6 + 45}
              y={y + height / 2 + 13}
              fontSize="12"
              stroke="#333"
              strokeOpacity="0.5"
            >
              {payload.value}
            </text>
          </>
        )}
        <image
          xlinkHref={`img/icons/${(disposalData as any)[payload.key]?.icon}`}
          width={50}
          height={50}
          x={isOut ? x - 6 : x + width}
          y={y + height / 2 - 25}
        />
      </Layer>
    );
  };

  const MyCustomLink = ({
    sourceX,
    sourceY,
    sourceControlX,
    targetX,
    targetY,
    targetControlX,
    linkWidth,
    payload,
  }: any) => {
    const seed = Number(payload.source.key) + Number(payload.target.key) * 100;
    const random = pseudoRandom(seed);

    return (
      <path
        className="recharts-sankey-link"
        d={`
          M${sourceX},${sourceY}
          C${sourceControlX},${sourceY} ${targetControlX},${targetY} ${targetX},${targetY}
        `}
        fill="none"
        stroke={`hsl(${Math.floor(random * 360)},100%,50%)`}
        strokeWidth={linkWidth}
        strokeOpacity="0.2"
      />
    );
  };

  if (data.links.length === 0 || data.nodes.length === 0) {
    return <div />;
  }

  const additionalProps = forRender ? { width, height } : {};

  const chart = (
    <Sankey
      data={data}
      node={<MyCustomNode />}
      link={<MyCustomLink />}
      nodePadding={40}
      margin={{
        right: 200,
        bottom: 20,
      }}
      {...additionalProps}
    >
      <Tooltip />
    </Sankey>
  );

  if (forRender) {
    return chart;
  }

  return <ResponsiveContainer height={800}>{chart}</ResponsiveContainer>;
}
