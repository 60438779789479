import { IVariant, IVariantDCOV } from "../../interfaces";
import {
  sanaceReconstructionGroupProvider,
  sanaceReconstructionProvider,
} from "../../providers/sanaceReconstructionProvider";
import { currencyFormatter } from "../../utils";
import { getPrice } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getSanaceReconstruction = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.sanaceReconstruction.filter((g) => g.quantity > 0);
  const groups = sanaceReconstructionGroupProvider(filtered);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    {
      text: "Rekonstrukce kanalizace - rekonstrukce kanalizačních šachet",
      style: "h4",
    },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: ["auto", "auto", "auto", "*"],
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Cena za 1ks (bez DPH)", style: "tableHeader" },
            { text: "Počet", style: "tableHeader" },
            { text: "Cena", style: "tableHeader" },
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            currencyFormatter(g.price),
            g.quantity,
            getPrice(g, sanaceReconstructionProvider)[1],
          ]),
          ["", "", "", ""],
          ...groups.map((g, index) => [
            `Mezisoučet #${index + 1}`,
            currencyFormatter(g.price),
            g.quantity,
            getPrice(g, sanaceReconstructionProvider)[1],
          ]),
          [
            "Celkový součet",
            "",
            "",
            getPrice(filtered, sanaceReconstructionProvider)[1],
          ],
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getSanaceReconstruction;
