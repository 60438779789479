import mze15_11 from "../data/mze/15-11.json";
import mmr_pressured from "../data/mmr/pressured.json";
import { ICalculation, ISewerage } from "../interfaces";
import { includeVat, calculateCosts, getGroups } from "../utils";
import { defaultQuantity } from "./utils";

export function pressuredPriceProvider(data: ISewerage, method: string) {
  // If data for MMR is not found, fallback to MZE
  const mmrCalc = mmrProvider(data);
  if (method === "mmr" && mmrCalc !== false) {
    return mmrCalc;
  } else {
    const genuine = "mze" === method;
    const type = (mze15_11.data as any)[data.type];
    const profiles = data.consolidated ? type.z : type.n;
    const money = includeVat(
      (profiles as any)[data.profile] * data.quantity,
      !mze15_11.vat_included
    );
    return calculateCosts(money, mze15_11.lifespan, genuine);
  }
}

function mmrProvider(data: ISewerage): ICalculation | false {
  const money = includeVat(
    mmr_pressured.price * data.quantity,
    !mmr_pressured.vat_included
  );
  return calculateCosts(money, mmr_pressured.lifespan, true);
}

export function pressuredOptionProvider(state: ISewerage) {
  const types = Object.keys(mze15_11.data);
  const profiles = Object.keys((mze15_11.data as any)[state.type].n);

  return { types, profiles };
}

export function pressuredGroupProvider(data: ISewerage[]): ISewerage[] {
  return getGroups(data, ["type", "profile", "consolidated"], ["quantity"]);
}

export const pressuredDefaultState = {
  type: "litina",
  profile: "80",
  consolidated: false,
  quantity: defaultQuantity,
};
