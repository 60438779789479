import { ISaveState } from "./interfaces";
import { detailedGeoDefaultState } from "./providers/detailedGeoProvider";
import { disposalDefaultState } from "./providers/disposalProvider";
import { indicativeGeoDefaultState } from "./providers/indicativeGeoProvider";
import { nonTableDefaultState } from "./providers/nonTableProvider";
import { pressuredStationLineDefaultState } from "./providers/pressuredStationLineProvider";
import { sanaceConnectionDefaultState } from "./providers/sanaceConnectionProvider";
import { sanaceDefaultState } from "./providers/sanaceProvider";
import { sanaceReconstructionBigDefaultState } from "./providers/sanaceReconstructionBigProvider";
import { sanaceReconstructionDefaultState } from "./providers/sanaceReconstructionProvider";

function omit(key: string, obj: { [key: string]: any }) {
  const { [key]: omitted, ...rest } = obj;
  return rest;
}

const v1tov2 = (state: any) => ({
  ...state,
  dcov: {
    ...omit("image", state.dcov),
    sanace: [sanaceDefaultState],
    sanaceConnection: [sanaceConnectionDefaultState],
    sanaceReconstruction: [sanaceReconstructionDefaultState],
    sanaceReconstructionBig: [sanaceReconstructionBigDefaultState],
    sanaceRoad: 0,
    images: typeof state.dcov.image === "string" ? [state.dcov.image] : [],
    land: state.dcov.land.map((land: any) => ({
      ...omit("image", land),
      images: typeof land.image === "string" ? [land.image] : [],
    })),
  },
  variants: state.variants.map((variant: any) => ({
    ...omit("image", variant),
    sanace: [sanaceDefaultState],
    sanaceConnection: [sanaceConnectionDefaultState],
    sanaceReconstruction: [sanaceReconstructionDefaultState],
    sanaceReconstructionBig: [sanaceReconstructionBigDefaultState],
    sanaceRoad: 0,
    images: typeof variant.image === "string" ? [variant.image] : [],
    land: variant.land.map((land: any) => ({
      ...omit("image", land),
      images: typeof land.image === "string" ? [land.image] : [],
    })),
  })),
  version: 2,
});

const v2tov3 = (state: any) => {
  // Hack, because I forgot to add the version number during first migration :(
  // Data is already in the correct format, so just add the version number
  if (typeof state?.dcov?.nonTable !== "undefined") {
    return {
      ...state,
      version: 3,
    };
  }

  return {
    ...state,
    dcov: {
      ...state.dcov,
      nonTable: [nonTableDefaultState],
      detailedGeo: {
        text: "",
        quantity: state.dcov.detailedGeo,
      },
      indicativeGeo: {
        text: "",
        quantity: state.dcov.indicativeGeo,
      },
    },
    variants: state.variants.map((variant: any) => ({
      ...variant,
      nonTable: [nonTableDefaultState],
      detailedGeo: {
        text: "",
        quantity: variant.detailedGeo,
      },
      indicativeGeo: {
        text: "",
        quantity: variant.indicativeGeo,
      },
      pressuredStation: [
        {
          text: "",
          quantity: variant.pressuredStation,
        },
      ],
    })),
    version: 3,
  };
};

const v3tov4 = (state: any) => {
  return {
    ...state,
    version: 4,
    dcov: {
      ...state.dcov,
      detailedGeo: state.dcov.detailedGeo
        ? [
            {
              quantity: state.dcov.detailedGeo.quantity,
              name: state.dcov.detailedGeo.text,
            },
          ]
        : [detailedGeoDefaultState],
      indicativeGeo: state.dcov.indicativeGeo
        ? [
            {
              quantity: state.dcov.indicativeGeo.quantity,
              name: state.dcov.indicativeGeo.text,
            },
          ]
        : [indicativeGeoDefaultState],
      disposalExpanded: false,
      disposal: [disposalDefaultState],
    },
    variants: state.variants.map((variant: any) => ({
      ...variant,
      detailedGeo: variant.detailedGeo
        ? [
            {
              quantity: variant.detailedGeo.quantity,
              name: variant.detailedGeo.text,
            },
          ]
        : [detailedGeoDefaultState],
      indicativeGeo: variant.indicativeGeo
        ? [
            {
              quantity: variant.indicativeGeo.quantity,
              name: variant.indicativeGeo.text,
            },
          ]
        : [indicativeGeoDefaultState],
    })),
  };
};

const v4tov5 = (state: any) => {
  return {
    ...state,
    version: 5,
    variants: state.variants.map((variant: any) => ({
      ...variant,
      pressuredStationLine: [pressuredStationLineDefaultState],
    })),
  };
};

const v5tov6 = (state: any) => {
  // 3.7: kameninové obetonované -> kameninové
  // 3.1, 3.2: kameninové obetonované -> kameninové, štěrk lože
  return {
    ...state,
    version: 6,
    variants: state.variants.map((variant: any) => ({
      ...variant,
      gravitational: variant.gravitational.map((gravitational: any) => ({
        ...gravitational,
        type: gravitational.type.replace(
          "kameninové obetonované",
          "kameninové, štěrk lože"
        ),
      })),
      gravitationalConnection: variant.gravitationalConnection.map(
        (gravitationalConnection: any) => ({
          ...gravitationalConnection,
          type: gravitationalConnection.type.replace(
            "kameninové obetonované",
            "kameninové"
          ),
        })
      ),
    })),
    dcov: {
      ...state.dcov,
      gravitationalConnection: state.dcov.gravitationalConnection.map(
        (gravitationalConnection: any) => ({
          ...gravitationalConnection,
          type: gravitationalConnection.type.replace(
            "kameninové obetonované",
            "kameninové"
          ),
        })
      ),
    },
  };
};

const transformers = {
  1: v1tov2,
  2: v2tov3,
  3: v3tov4,
  4: v4tov5,
  5: v5tov6,
};

const migrateSaveState = (state: any): ISaveState => {
  let newState = state;
  let version: keyof typeof transformers = state.version;
  // Incrementally apply migrations until we reach the latest version
  while (version in transformers) {
    newState = transformers[version](newState);
    version = newState.version;
  }
  return newState;
};

export default migrateSaveState;
