import { IVariant, IVariantDCOV } from "../../interfaces";
import {
  sanaceConnectionGroupProvider,
  sanaceConnectionProvider,
} from "../../providers/sanaceConnectionProvider";
import { currencyFormatter } from "../../utils";
import { getPrice } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getSanaceConnection = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.sanaceConnection.filter((g) => g.quantity > 0);
  const groups = sanaceConnectionGroupProvider(filtered);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    {
      text: "Rekonstrukce kanalizace - napojení domovních přípojek",
      style: "h4",
    },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: ["auto", "auto", "auto", "*"],
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Cena za 1ks (bez DPH)", style: "tableHeader" },
            { text: "Počet", style: "tableHeader" },
            { text: "Cena", style: "tableHeader" },
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            currencyFormatter(g.price),
            g.quantity,
            getPrice(g, sanaceConnectionProvider)[1],
          ]),
          ["", "", "", ""],
          ...groups.map((g, index) => [
            `Mezisoučet #${index + 1}`,
            currencyFormatter(g.price),
            g.quantity,
            getPrice(g, sanaceConnectionProvider)[1],
          ]),
          [
            "Celkový součet",
            "",
            "",
            getPrice(filtered, sanaceConnectionProvider)[1],
          ],
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getSanaceConnection;
