import { IVariant, IVariantDCOV } from "../../interfaces";
import { indicativeGeoPriceProvider } from "../../providers/indicativeGeoProvider";
import { getPrice, getWidths, getPriceHeader } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getIndicativeGeo = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.indicativeGeo.filter((g) => g.quantity > 0);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    {
      stack: [
        {
          text: "Orientační hydrogeologický průzkum (pro plošnou zálivku)",
          style: "h4",
        },
        {
          layout: "lightHorizontalLines",
          table: {
            widths: getWidths(2),
            body: [
              [
                { text: "Název větve", style: "tableHeader" },
                { text: "Počet", style: "tableHeader" },
                ...getPriceHeader(),
              ],
              ...filtered.map((g) => [
                getOstr(g.name),
                g.quantity,
                ...getPrice(g, indicativeGeoPriceProvider),
              ]),
              ["", "", "", ""],
              [
                "Celkový součet",
                "",
                ...getPrice(filtered, indicativeGeoPriceProvider),
              ],
            ],
          },
        },
      ],
      unbreakable: true,
      ...getItemMargin(),
    },
  ];
};

export default getIndicativeGeo;
