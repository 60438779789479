import {
  ICalculation,
  IVariant,
  IVariantDCOV,
  ISewerage,
  ISewerageConnection,
  ITypeQuantity,
  IPriceQuantity,
  INonTable,
  IQuantity,
} from "./interfaces";

import { sumCalc, roundCalc } from "./utils";

import { gravitationalPriceProvider } from "./providers/gravitationalProvider";
import { pressuredPriceProvider } from "./providers/pressuredProvider";
import { gravitationalConnectionPriceProvider } from "./providers/gravitationalConnectionProvider";
import { covPriceProvider } from "./providers/covProvider";
import { seepagePriceProvider } from "./providers/seepageProvider";
import { pressuredStationPriceProvider } from "./providers/pressuredStationProvider";
import { detailedGeoPriceProvider } from "./providers/detailedGeoProvider";
import { indicativeGeoPriceProvider } from "./providers/indicativeGeoProvider";
import { sanaceProvider } from "./providers/sanaceProvider";
import { sanaceConnectionProvider } from "./providers/sanaceConnectionProvider";
import { sanaceReconstructionProvider } from "./providers/sanaceReconstructionProvider";
import { sanaceReconstructionBigProvider } from "./providers/sanaceReconstructionBigProvider";
import { sanaceRoadProvider } from "./providers/sanaceRoadProvider";
import { nonTablePriceProvider } from "./providers/nonTableProvider";

const calculateArray = (
  data: any[],
  provider: (data: any, method: string) => ICalculation,
  method: string
) =>
  data.reduce(
    (acc: ICalculation, item) => sumCalc([provider(item, method), acc]),
    {
      investment: 0,
      min: 0,
      max: 0,
    }
  );

const calculateCovs = (data: ITypeQuantity[], method: string) =>
  calculateArray(data, covPriceProvider, method);

const calculatePointSeepages = (data: ITypeQuantity[], method: string) =>
  calculateArray(data, seepagePriceProvider, method);

const calculateGravitationalConnections = (
  data: ISewerageConnection[],
  method: string
) => calculateArray(data, gravitationalConnectionPriceProvider, method);

const calculateGravitationals = (data: ISewerage[], method: string) =>
  calculateArray(data, gravitationalPriceProvider, method);

const calculatePressureds = (data: ISewerage[], method: string) =>
  calculateArray(data, pressuredPriceProvider, method);

const calculatePressuredStations = (data: IQuantity[], method: string) =>
  calculateArray(data, pressuredStationPriceProvider, method);

const calculateSanaces = (data: ITypeQuantity[], method: string) =>
  calculateArray(data, sanaceProvider, method);

const calculateSanaceConnections = (data: IPriceQuantity[], method: string) =>
  calculateArray(data, sanaceConnectionProvider, method);

const calculateSanaceReconstructions = (
  data: IPriceQuantity[],
  method: string
) => calculateArray(data, sanaceReconstructionProvider, method);

const calculateSanaceReconstructionsBig = (
  data: IPriceQuantity[],
  method: string
) => calculateArray(data, sanaceReconstructionBigProvider, method);

const calculateIndicativeGeos = (data: IQuantity[], method: string) =>
  calculateArray(data, indicativeGeoPriceProvider, method);

const calculateDetailedGeos = (data: IQuantity[], method: string) =>
  calculateArray(data, detailedGeoPriceProvider, method);

const calculateNonTables = (data: INonTable[], method: string) =>
  calculateArray(data, nonTablePriceProvider, method);

export const calculateVariant = (variant: IVariant, method: string) =>
  roundCalc(
    sumCalc([
      calculateGravitationals(variant.gravitational, method),
      calculatePressureds(variant.pressured, method),
      calculateGravitationalConnections(
        variant.gravitationalConnection,
        method
      ),
      calculatePressuredStations(variant.pressuredStation, method),
      calculateIndicativeGeos(variant.indicativeGeo, method),
      calculateDetailedGeos(variant.detailedGeo, method),
      calculateCovs(variant.cov, method),
      calculatePointSeepages(variant.seepage, method),
      calculateSanaces(variant.sanace, method),
      calculateSanaceConnections(variant.sanaceConnection, method),
      calculateSanaceReconstructions(variant.sanaceReconstruction, method),
      calculateSanaceReconstructionsBig(
        variant.sanaceReconstructionBig,
        method
      ),
      sanaceRoadProvider(variant.sanaceRoad, method),
      calculateNonTables(variant.nonTable, method),
    ])
  );

// DCOV is calculated only with MMR for now
export const calculateDCOV = (variant: IVariantDCOV, method: string) =>
  roundCalc(
    sumCalc([
      calculateGravitationalConnections(
        variant.gravitationalConnection,
        method
      ),
      calculateIndicativeGeos(variant.indicativeGeo, method),
      calculateDetailedGeos(variant.detailedGeo, method),
      calculateCovs(variant.cov, method),
      calculatePointSeepages(variant.seepage, method),
      calculateSanaces(variant.sanace, method),
      calculateSanaceConnections(variant.sanaceConnection, method),
      calculateSanaceReconstructions(variant.sanaceReconstruction, method),
      calculateSanaceReconstructionsBig(
        variant.sanaceReconstructionBig,
        method
      ),
      sanaceRoadProvider(variant.sanaceRoad, method),
      calculateNonTables(variant.nonTable, method),
    ])
  );

export default function calculate(
  variant: IVariant,
  dcovVariant: IVariantDCOV
) {
  const mmr = calculateVariant(variant, "mmr");
  const mze = calculateVariant(variant, "mze");
  const dcov_mmr = calculateDCOV(dcovVariant, "mmr");
  const dcov_mze = calculateDCOV(dcovVariant, "mze");

  return [
    {
      name: "Ceny kanalizace dle MMR",
      ...mmr,
    },
    {
      name: "Ceny kanalizace dle MZe",
      ...mze,
    },
    {
      name: "Domovní ČOV dle MMR",
      ...dcov_mmr,
    },
    {
      name: "Domovní ČOV dle MZe",
      ...dcov_mze,
    },
  ];
}
