import mmr3_16 from "../data/mmr/3.16.json";
import mmr48 from "../data/mmr/48.json";
import mze10_1 from "../data/mze/10-1.json";
import mze10_2 from "../data/mze/10-2.json";
import { includeVat, calculateCosts, sumCalc, getGroups } from "../utils";
import { ITypeQuantity } from "../interfaces";
import { defaultQuantity } from "./utils";

export function covPriceProvider(data: ITypeQuantity, method: string) {
  const covEo = getCovEO(data.type);

  if (method === "mmr") {
    if (covEo <= 500) {
      return (
        Object.entries(mmr3_16.data)
          // Strip technology and construction part from the name
          .map(([key, value]) => ({ key: key.split(" - ")[0], value }))
          .filter(({ key }) => key === data.type)
          // Sum prices from all parts
          .reduce(
            (acc, { value }) =>
              sumCalc([
                calculateCosts(
                  includeVat(
                    value.price * data.quantity,
                    !mmr3_16.vat_included
                  ),
                  value.lifespan,
                  true
                ),
                acc,
              ]),
            { investment: 0, min: 0, max: 0, genuine: true }
          )
      );
    } else {
      // We have to multiply it by EO, because the price is per EO
      const money =
        (mmr48 as any).data[covEo.toString()] * covEo * data.quantity;
      return calculateCosts(
        includeVat(money, !mmr48.vat_included),
        mmr48.lifespan,
        true
      );
    }
  }

  if (method === "mze") {
    // <= 5 EO Domovní mikročistírna is used
    if (covEo <= 5) {
      const type = "Domovní mikročistírna";
      return calculateCosts(
        includeVat(
          mze10_1.data[type].price * data.quantity,
          !mze10_1.vat_included
        ),
        mze10_1.data[type].lifespan,
        true
      );
      // Here get nearest 100 from covEo and use that in lookup table in mze10_2
    } else {
      const nearest100 = Math.ceil(covEo / 100) * 100;
      const type = (mze10_2 as any).data[nearest100.toString()];

      // We have to multiply the price by EO, because the price is per EO
      return sumCalc([
        calculateCosts(
          includeVat(
            type.technology.price_eo * covEo * data.quantity,
            !mze10_2.vat_included
          ),
          type.technology.lifespan,
          true
        ),
        calculateCosts(
          includeVat(
            type.building.price_eo * covEo * data.quantity,
            !mze10_2.vat_included
          ),
          type.building.lifespan,
          true
        ),
      ]);
    }
  }

  throw new Error("Method not implemented");
}

export function covOptionProvider() {
  const types1 = Object.keys(mmr3_16.data)
    // Remove storage tank, because we are calculating it separately
    .filter(
      (type) =>
        type !== "Žumpa celoplastová osazená na bet. desku s obetonováním"
    )
    // Strip technology and construction part from the name
    .map((type) => type.split(" - ")[0]);

  const types2 = Object.keys(mmr48.data).map((type) => `ČOV pro ${type} EO`);

  // Deduplicate
  return [...Array.from(new Set(types1)), ...types2];
}

// Get number of EO from COV type string
const getCovEO = (type: string) => {
  // Example: ČOV pro 265-300 EO
  const re = /ČOV pro (?:\d+-)?(\d+) EO/;
  const match = type.match(re);
  if (match === null) {
    throw new Error(`Invalid COV type: ${type}`);
  }
  return parseInt(match[1]);
};

export function covGroupProvider(data: ITypeQuantity[]): ITypeQuantity[] {
  return getGroups(data, ["type"], ["quantity"]);
}

export const covDefaultState = {
  type: "ČOV pro 3-5 EO",
  quantity: defaultQuantity,
};
