import mmr3_16 from "../data/mmr/3.16.json";
import mze10_1 from "../data/mze/10-1.json";
import { ISizeQuantity } from "../interfaces";
import { calculateCosts, getGroups, includeVat } from "../utils";
import { defaultQuantity } from "./utils";

export function storageTankPriceProvider(data: ISizeQuantity, method: string) {
  if (method === "mmr") {
    const type =
      mmr3_16.data["Žumpa celoplastová osazená na bet. desku s obetonováním"];
    return calculateCosts(
      includeVat(type.price * data.quantity * data.size, !mmr3_16.vat_included),
      type.lifespan,
      true
    );
  }

  if (method === "mze") {
    const type = mze10_1.data["Bezodtoká jímka"];
    return calculateCosts(
      // MZe doesn't calculate with size
      includeVat(type.price * data.quantity, !mze10_1.vat_included),
      type.lifespan,
      true
    );
  }

  throw new Error("Method not implemented");
}

export function storageTankGroupProvider(
  data: ISizeQuantity[]
): ISizeQuantity[] {
  return getGroups(data, ["size"], ["quantity"]);
}

export const storageTankDefaultState = {
  size: 2,
  quantity: defaultQuantity,
};
